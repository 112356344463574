import { KeyValue, TitleCasePipe } from '@angular/common';
import { Component, Input, OnInit } from '@angular/core';

import {
  IContactAddress,
  IContactEmail,
  IContactNumber,
  IName,
  ProposalInformation,
} from '@shared/interfaces';
import {
  extractDefaultValue,
  getArrangedName,
  thousandSeparatorAdd,
} from '@shared/utils';

import { DateTimeFormatService } from '../../../../services/date-time-format.service';
import { ClientSetupResponseDTO } from '../../../setup/general/services/client-data.service';

@Component({
  selector: 'app-print-medical-proposal-component',
  templateUrl: './print-medical-proposal.component.html',
  styleUrls: ['./print-medical-proposal.component.scss'],
})
export class MedicalProposalTemplateComponent implements OnInit {
  @Input() proposal_information: ProposalInformation;
  @Input() ref_number: string;
  @Input() client_information: ClientSetupResponseDTO;
  @Input() created_on: string;
  @Input() communication_modes: string;
  @Input() communication_languages: string;
  @Input() isMedicalGroup: boolean;
  @Input() coverages: KeyValue<string, string>[];
  @Input() covers: KeyValue<string, string>[];

  thousandSeparatorAdd = thousandSeparatorAdd;

  public coverSectionNumber = '03.';
  public creditSectionNumber = '05.';

  constructor(
    public dateTimeFormatService: DateTimeFormatService,
    public titleCasePipe: TitleCasePipe
  ) {}

  ngOnInit(): void {
    this.onInit();
  }

  onInit() {
    if (
      !this.isMedicalGroup &&
      this.proposal_information?.member_details.length === 0
    ) {
      this.coverSectionNumber = '03.';
    }
    if (
      !this.isMedicalGroup &&
      this.proposal_information?.member_details.length > 0
    ) {
      this.coverSectionNumber = '04.';
    }

    if (this.isMedicalGroup) {
      this.creditSectionNumber = '03.';
    }

    if (
      !this.isMedicalGroup &&
      this.proposal_information?.additional_covers_details?.covers.length ===
        0 &&
      this.proposal_information?.member_details.length === 0
    ) {
      this.creditSectionNumber = '03.';
    }

    if (
      !this.isMedicalGroup &&
      this.proposal_information?.additional_covers_details?.covers.length > 0 &&
      this.proposal_information?.member_details.length === 0
    ) {
      this.creditSectionNumber = '04.';
    }

    if (
      !this.isMedicalGroup &&
      this.proposal_information?.additional_covers_details?.covers.length ===
        0 &&
      this.proposal_information?.member_details.length > 0
    ) {
      this.creditSectionNumber = '04.';
    }

    if (
      !this.isMedicalGroup &&
      this.proposal_information?.additional_covers_details?.covers.length > 0 &&
      this.proposal_information?.member_details.length > 0
    ) {
      this.creditSectionNumber = '05.';
    }
  }

  getFormattedDate(value: string | Date) {
    return this.dateTimeFormatService?.formatDateAsString(new Date(value));
  }

  getName(name: IName) {
    return getArrangedName(name);
  }

  getPhone(contact: IContactNumber[]) {
    return extractDefaultValue({
      type: 'PHONE',
      value: contact,
    });
  }

  getEmail(email: IContactEmail[]) {
    return extractDefaultValue({
      type: 'EMAIL',
      value: email,
    });
  }

  getAddress(address: IContactAddress[]) {
    return extractDefaultValue({
      type: 'ADDRESS',
      value: address,
    });
  }

  getStringForBoolean(value: boolean): 'YES' | 'NO' {
    return value ? 'YES' : 'NO';
  }

  getCoverageName(key: string): string {
    return this.coverages?.find((a) => a?.key === key)?.value;
  }

  getCoverName(key: string): string {
    return this.covers?.find((a) => a?.key === key)?.value;
  }
}
