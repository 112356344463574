export enum ENDORSEMENT_DOC_TYPES {
  NAME_RELATED = 'NAME RELATED',
  ADDRESS_RELATED = 'ADDRESS RELATED',
  CLAUSE_RELATED = 'CLAUSE RELATED',
  COVERS_RELATED = 'COVERS RELATED',
  VEHICLE_RELATED = 'VEHICLE RELATED',
  SUM_INSURED_RELATED = 'SUM INSURED RELATED',
  LIVES_RELATED = 'LIVES RELATED',
  TRAVEL_PERIOD_RELATED = 'TRAVEL PERIOD RELATED',
  TRAVEL_PRODUCT_PLAN_CHANGE = 'TRAVEL_PRODUCT_PLAN_CHANGE',
}

export enum ENDORSEMENT_CRITERIA {
  NAME_CHANGE = 'Insured Name Change',
  ADDRESS_CHANGE = 'Insured Address Change',
  VEHICLE_DETAILS_CHANGE = 'Vehicle Details Changes',
  CLAUSES_CHANGE = 'Inclusion/ Deletion of Clauses',
  VEHICLE_CLAUSES_CHANGE = 'Inclusion/ Deletion of Clauses/ Exclusions',
  COVERS_CHANGE = 'Inclusion/ Deletion of Additional Loadings/ Rebates',
  SUM_INSURED_CHANGE = 'Increase/ Decrease Sum Insured',

  LIVES_COVERED_CHANGE = 'Lives to be Covered Changes',

  TRAVEL_PERIOD_REDUCTION_EXTENSION = 'Travel Period Reduction/ Extension',
  NATURE_PRODUCT_PLAN_CHANGES = 'Product Plan Changes',
}

export enum VEHICLE_DETAIL_FIELDS {
  FUEL_TYPE = 'Fuel Type',
  CUBIC_CAPACITY = 'Cubic Capacity',
  MANUFACTURE_YEAR = 'Manufacture Year',
  VEHICLE_TYPE = 'Vehicle Type',
  VEHICLE_MAKE = 'Vehicle Make',
  VEHICLE_MODEL = 'Vehicle Model',
  SEATING_CAPACITY = 'Seating Capacity',
}

export enum ENDORSEMENT_STATUS {
  PROCESSING = 'PROCESSING',
  NEW = 'NEW',
  DELETED = 'DELETED',
  APPROVED = 'APPROVED',
  REJECTED = 'REJECTED',
  RETURNED_TO_UNDERWRITER = 'RETURN TO UNDERWRITER',
  PENDING_SUPERVISOR_APPROVAL = 'PENDING SUPERVISOR APPROVAL',
  PENDING_PUBLICATION = 'PENDING PUBLICATION',
  PUBLISHED = 'PUBLISHED',
  PUBLISHED_ACTIVATION_PENDING = 'PUBLISHED (ACTIVATION PENDING)',
}

export enum ENDORSEMENT_TYPES {
  POLICY_CHANGES = 'POLICY CHANGES',
  VEHICLE_INCLUSION = 'VEHICLE INCLUSION',
  VEHICLE_DELETION = 'VEHICLE DELETION',
  MEMBER_INCLUSION = 'MEMBER INCLUSION',
  MEMBER_DELETION = 'MEMBER DELETION',
}

export enum SUM_ASSURED_CHANGE {
  INCREASED = 'INCREASED',
  DECREASED = 'DECREASED',
  NEUTRAL = 'NEUTRAL',
}
