import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Observable, Subscription } from 'rxjs';

import { PremiumCalculationData, PremiumCalSubInfo } from '@shared/interfaces';
import { thousandSeparatorAdd } from '@shared/utils';

import { FlowControlService } from '../../../../services/flow-control.service';
import { CurrenciesService } from '../../../setup/currencies/services/currencies.service';

@Component({
  selector: 'premium-displayer',
  templateUrl: './premium-displayer.component.html',
  styleUrls: ['./premium-displayer.component.scss'],
})
export class DisplayPremiumSectionComponent implements OnInit {
  @Input() premiumCalculationData: PremiumCalculationData;
  @Input() baseCurrency: string;
  @Input() premiumCalculationDataUpdates: Observable<PremiumCalculationData>;

  covers: PremiumCalSubInfo[] = [];
  fees_and_charges: PremiumCalSubInfo[] = [];
  premium: number;

  thousandSeparatorAdd = thousandSeparatorAdd;
  subscriptions = new Subscription();

  constructor(
    route: ActivatedRoute,
    private router: Router,
    private flowControlService: FlowControlService,
    private currenciesService: CurrenciesService
  ) {
    this.flowControlService.updateRouteInfo(
      this.router.url.split('?')[0],
      route.snapshot.data
    );
  }
  ngOnInit(): void {
    // this.subscriptions.add(
    this.premiumCalculationDataUpdates?.subscribe((data) => {
      if (data) {
        this.premiumCalculationData = data;

        // Update dependent properties after receiving data
        this.covers = this.premiumCalculationData.covers;
        this.fees_and_charges = this.premiumCalculationData.fees_and_charges;
        this.premium = this.premiumCalculationData.premium_amount;

        // this.setData(); // Uncomment if needed for further data setup
      }
    });
    // );

    // Initialize the base currency
    this.baseCurrency = this.baseCurrency
      ? `(${this.baseCurrency})`
      : `(${this.currenciesService.baseCurrency.code})`;
  }
}
